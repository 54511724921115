import { useState, useCallback } from 'react'
import type { NextPage } from 'next'
import dynamic from 'next/dynamic'
import Head from 'next/head'

const LoginTemplate = dynamic(
  () => import('../components/templates/LoginTemplate'),
  { ssr: false },
)

export type LoginPage = 'top' | 'login' | 'register' | 'resetPassword'

const Login: NextPage = () => {
  const [page, setPage] = useState<LoginPage>('top')
  const onClickPage = useCallback(
    (page: LoginPage) => () => {
      setPage(page)
    },
    [setPage],
  )

  return (
    <div>
      <Head>
        <title>ログイン | YOMOGI+</title>
      </Head>
      <LoginTemplate page={page} onClickPage={onClickPage} />
    </div>
  )
}

export default Login
